@import "base/global";
@import "layout/global";

.template-404 {
  height: 100vh;
  background-size: cover;
  main {
  display: flex;
  min-height: 90vh;
    }
  .content {
  font-family:$font-family-title;
  text-align: center;
  background-color: white;
  width: fit-content;
  border-radius: 4px;
  padding: 50px;
  margin:auto;
}
    h1 {
      font-size: 36px;
      font-family:$font-family-title;
      font-weight: 400;
      color: black;
    }
    p {
    font-size: 16px;
    }
}
