@charset "UTF-8";
@font-face {
  font-family: "Acumin";
  src: url("/public/fonts/AcuminRegular.eot");
  src: url("/public/fonts/AcuminRegular.eot?#iefix") format("embedded-opentype"), url("/public/fonts/AcuminRegular.woff2") format("woff2"), url("/public/fonts/AcuminRegular.woff") format("woff"), url("/public/fonts/AcuminRegular.svg#AcuminRegular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Acumin";
  src: url("/public/fonts/AcuminSemibold.eot");
  src: url("/public/fonts/AcuminSemibold.eot?#iefix") format("embedded-opentype"), url("/public/fonts/AcuminSemibold.woff2") format("woff2"), url("/public/fonts/AcuminSemibold.woff") format("woff"), url("/public/fonts/AcuminSemibold.ttf") format("truetype"), url("/public/fonts/AcuminSemibold.svg#AcuminPro-Medium") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Louize";
  src: url("/public/fonts/LouizeRegular.eot");
  src: url("/public/fonts/LouizeRegular.eot?#iefix") format("embedded-opentype"), url("/public/fonts/LouizeRegular.woff2") format("woff2"), url("/public/fonts/LouizeRegular.woff") format("woff"), url("/public/fonts/LouizeRegular.ttf") format("truetype"), url("/public/fonts/LouizeRegular.svg#Louize") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
.btn-primary {
  font-family: "Louize", "Times", serif;
  width: fit-content;
  text-transform: uppercase;
  height: 48px;
  color: #000000;
  padding: 8px 12px;
  font-size: 14px;
  white-space: nowrap;
  border-radius: 4px;
  line-height: 32px;
  border: 1px solid #000000;
  background: none;
  transition: all 0.3s ease-in-out;
}
.btn-primary:hover {
  color: #FFFFFF;
  text-transform: uppercase;
  border: 1px solid #000000;
  background: #000000;
  transition: all 0.3s ease-in-out;
}

.btn-secondary {
  font-family: "Louize", "Times", serif;
  width: fit-content;
  text-transform: uppercase;
  height: 48px;
  white-space: nowrap;
  color: #00a062;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 4px;
  line-height: 32px;
  border: 1px solid #00a062;
  background: none;
  transition: all 0.3s ease-in-out;
}
.btn-secondary:hover {
  color: #FFFFFF;
  text-transform: uppercase;
  border: 1px solid #00a062;
  background: #00a062;
  transition: all 0.3s ease-in-out;
}

.btn-secondary-more {
  font-family: "Louize", "Times", serif;
  width: fit-content;
  text-transform: uppercase;
  height: 48px;
  white-space: nowrap;
  color: #00a062;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 4px;
  line-height: 32px;
  border: 1px solid #00a062;
  background: none;
  transition: all 0.3s ease-in-out;
  position: relative;
  min-width: 135px;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: space-evenly;
}
.btn-secondary-more:hover {
  color: #FFFFFF;
  text-transform: uppercase;
  border: 1px solid #00a062;
  background: #00a062;
  transition: all 0.3s ease-in-out;
  /* Changez l'image au survol */
}
.btn-secondary-more:hover:after {
  background-image: url("/public/img/icons2024/plus-white.svg"); /* Changez le chemin vers votre nouvelle image */
}
.btn-secondary-more:after {
  content: "";
  width: 16px;
  height: 16px;
  background-image: url("/public/img/icons2024/plus-green.svg");
  background-size: cover;
  transition: all 0.3s ease-in-out;
}

.btn-secondary-less {
  font-family: "Louize", "Times", serif;
  width: fit-content;
  text-transform: uppercase;
  height: 48px;
  white-space: nowrap;
  color: #00a062;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 4px;
  line-height: 32px;
  border: 1px solid #00a062;
  background: none;
  transition: all 0.3s ease-in-out;
  position: relative;
  min-width: 135px;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: space-evenly;
}
.btn-secondary-less:hover {
  color: #FFFFFF;
  text-transform: uppercase;
  border: 1px solid #00a062;
  background: #00a062;
  transition: all 0.3s ease-in-out;
}
.btn-secondary-less:hover:after {
  background-image: url("/public/img/icons2024/minus-white.svg");
}
.btn-secondary-less:after {
  content: "";
  width: 16px;
  height: 16px;
  background-image: url("/public/img/icons2024/minus-green.svg");
  background-size: cover;
  transition: all 0.3s ease-in-out;
}

.btn-white {
  font-family: "Louize", "Times", serif;
  width: fit-content;
  text-transform: uppercase;
  height: 48px;
  white-space: nowrap;
  color: #FFFFFF;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 4px;
  line-height: 32px;
  border: 1px solid #FFFFFF;
  background: none;
  transition: all 0.3s ease-in-out;
}
.btn-white:hover {
  color: #000000;
  text-transform: uppercase;
  border: 1px solid #FFFFFF;
  background: #FFFFFF;
  transition: all 0.3s ease-in-out;
}

.btn-white-full {
  font-family: "Louize", "Times", serif;
  width: fit-content;
  white-space: nowrap;
  text-transform: uppercase;
  height: 48px;
  color: #00a062;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 4px;
  line-height: 32px;
  background: #FFFFFF;
  transition: all 0.3s ease-in-out;
}
.btn-white-full:hover {
  color: #00a062;
  text-transform: uppercase;
  background: #FFFFFF;
  transition: all 0.3s ease-in-out;
}

.btn-green {
  font-family: "Louize", "Times", serif;
  width: fit-content;
  text-transform: uppercase;
  height: 48px;
  color: #FFFFFF;
  padding: 8px 12px;
  font-size: 14px;
  white-space: nowrap;
  border-radius: 4px;
  line-height: 32px;
  border: 1px solid #00a062;
  background: #00a062;
  transition: all 0.3s ease-in-out;
}
.btn-green:hover {
  color: #FFFFFF;
  text-transform: uppercase;
  border: 1px solid #00a062;
  background: #00a062;
  transition: all 0.3s ease-in-out;
}

.btn-type {
  padding: 2px 24px !important;
  outline: none !important;
}

.bg-carbon {
  background: #222222 !important;
}

.bg-pine {
  background: #093F0C !important;
}

.bg-sand {
  background: #EFE7E3 !important;
}

.bg-grey {
  background: #828282 !important;
}

.bg-mint {
  background: #86ECBD !important;
}

.bg-mint-light {
  background: #f0fff7 !important;
}

.bg-rose {
  background: #FFE1D8 !important;
}

.bg-rose-light {
  background: #fceeea !important;
}

.bg-sand-light {
  background: #fffbf2 !important;
}

.bg-blue-light {
  background: #f6f7ff !important;
}

.bg-sand-wash {
  background: #f5f1ef !important;
}

.bg-opacity {
  background-color: rgba(255, 255, 255, 0.6509803922) !important;
}

.bg-viridian {
  background: #00a062 !important;
}

.btn-sand {
  font-family: "Louize", "Times", serif;
  width: fit-content;
  text-transform: uppercase;
  height: 48px;
  color: #00a062;
  padding: 8px 12px;
  font-size: 14px;
  white-space: nowrap;
  border-radius: 4px;
  line-height: 32px;
  border: 1px solid #00a062;
  background: #f5f1ef;
  transition: all 0.3s ease-in-out;
}
.btn-sand:hover {
  color: #00a062;
  text-transform: uppercase;
  border: 1px solid #00a062;
  background: #f5f1ef;
  transition: all 0.3s ease-in-out;
}

a {
  text-decoration: none;
  color: #000;
}

p a {
  text-decoration: underline;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2 {
  font-family: "Louize", "Times", serif;
}

.heading_title-h1 {
  font-family: "Louize", "Times", serif;
  font-size: 72px;
  text-transform: uppercase;
}

.heading_section-title {
  font-family: "Acumin", "Arial", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  line-height: 16px; /* 114.286% */
  letter-spacing: 1.12px;
  font-weight: 400;
  margin-bottom: 32px !important;
}

.text-green {
  color: #00a062;
}

.text-white {
  color: #FFFFFF;
}

.text-black {
  color: #000000;
}

p {
  font-family: "Acumin", "Arial", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

b, strong {
  font-weight: 600;
}

.bg-grey {
  background-color: #828282;
}

.bg-green {
  background-color: #00a062;
}

.bg-sand {
  background-color: #EFE7E3;
}

.bg-sand-wash {
  background-color: #f5f1ef;
}

.bg-white {
  background-color: white;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 12H19' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 5L19 12L12 19' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 12L5 12' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 19L5 12L12 5' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.accordion-button:after {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.3335 8H12.6668' stroke='white' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 3.33331V12.6666' stroke='white' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.3335 8H12.6668' stroke='white' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  transform: none;
}

.template-newsletter {
  background-size: cover;
  height: 100vh;
}
.template-newsletter main {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.template-newsletter .section_newletter {
  text-align: center;
  background-color: white;
  width: fit-content;
  height: fit-content;
  max-height: fit-content;
  min-height: fit-content;
  border-radius: 5px;
  padding: 1rem 1.5rem;
  margin: auto;
}
.template-newsletter .section_newletter a {
  color: black;
}
.template-newsletter .section_newletter a:hover {
  border-bottom: 1px solid #000;
}
.template-newsletter h1 {
  font-size: 37px;
  font-family: "Louize", "Times", serif !important;
  font-weight: 400;
  color: black;
}
.template-newsletter .hs-button {
  font-family: "Louize", "Times", serif !important;
  width: fit-content !important;
  text-transform: uppercase !important;
  height: 48px !important;
  color: #fff !important;
  padding: 8px 12px !important;
  font-size: 14px !important;
  white-space: nowrap !important;
  border-radius: 4px !important;
  line-height: 32px !important;
  background: #00a062 !important;
  transition: all 0.3s ease-in-out !important;
}
.template-newsletter .hs-form-booleancheckbox-display input[type=checkbox] {
  border-radius: 2px !important;
}
.template-newsletter .hs-form-booleancheckbox-display input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 0.999512L3.5 6.49951L1 3.99951' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
  background-size: 11px !important;
}
.template-newsletter .hs-form-booleancheckbox-display input:checked {
  background-color: #00a062 !important;
  border-color: #00a062 !important;
  border-radius: 2px !important;
}
.template-newsletter .hs-error-msg {
  color: red;
}

.section_newletter {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 20px;
}
@media screen and (min-width:768px) {
  .section_newletter {
    max-height: 400px;
    padding: 80px 40px;
  }
}
.section_newletter .newsletter .title {
  font-family: "Louize", "Times", serif !important;
  font-weight: 400;
  font-size: 36px;
  line-height: 64px;
  text-align: center;
  text-transform: uppercase;
}
@media screen and (min-width:768px) {
  .section_newletter .newsletter .title {
    font-size: 56px;
    line-height: 64px;
  }
}
@media screen and (min-width: 992px) {
  .section_newletter .newsletter .title {
    font-size: 56px;
    line-height: 64px;
  }
}
.section_newletter .hs_recaptcha {
  visibility: hidden;
  position: absolute;
  left: 0;
  bottom: 30%;
}
.section_newletter .hbspt-form {
  background: none;
  font-family: "Acumin", "Arial", sans-serif;
  font-weight: 300;
  padding-bottom: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
.section_newletter .hbspt-form form ul {
  padding: 0;
  margin: 0;
}
.section_newletter .hbspt-form form li {
  list-style: none;
}
.section_newletter .hbspt-form form .hs_email {
  order: 1;
}
.section_newletter .hbspt-form form .hs_email label {
  margin-bottom: 0;
  text-align: left;
  font-weight: 300;
}
.section_newletter .hbspt-form form .hs_email ul {
  padding: 0;
  margin: 0;
}
.section_newletter .hbspt-form form .hs_email .hs-input {
  width: 100%;
  align-self: center;
  background: #F5F1EF;
  border-radius: 4px;
  height: 35px;
  outline: none;
  padding: 0 5px;
  font-weight: 300;
  border: 1px solid #EFE7E3;
}
@media screen and (min-width:768px) {
  .section_newletter .hbspt-form form .hs_email .hs-input {
    width: 100%;
  }
}
.section_newletter .hbspt-form form .legal-consent-container {
  padding: 5px 0;
}
.section_newletter .hbspt-form form .legal-consent-container .hs-form-booleancheckbox-display input {
  margin-top: 0;
  outline: none;
}
.section_newletter .hbspt-form form .legal-consent-container .hs-form-booleancheckbox-display span {
  font-size: 11px;
  line-height: 1.2 !important;
  text-align: center;
}
.section_newletter .hbspt-form form .legal-consent-container .hs-form-booleancheckbox-display span p {
  margin: 0;
  font-size: 11px;
  line-height: 1.2 !important;
  text-align: center;
}
.section_newletter .hbspt-form form .legal-consent-container ul {
  padding: 0;
  margin: 0;
}
.section_newletter .hbspt-form form .hs_submit {
  height: 35px;
  margin-top: -10px;
}
.section_newletter .hbspt-form form .hs_submit .hs-button {
  font-family: "Louize", "Times", serif;
  width: fit-content;
  text-transform: uppercase;
  height: 48px;
  color: #FFFFFF;
  padding: 8px 12px;
  font-size: 14px;
  white-space: nowrap;
  border-radius: 4px;
  line-height: 32px;
  border: 1px solid #00a062;
  background: #00a062;
  transition: all 0.3s ease-in-out;
}
.section_newletter .hbspt-form form .hs_submit .hs-button:hover {
  color: #FFFFFF;
  text-transform: uppercase;
  border: 1px solid #00a062;
  background: #00a062;
  transition: all 0.3s ease-in-out;
}
.section_newletter .hbspt-form form .hs_error_rollup {
  display: none;
}
.section_newletter .hbspt-form form .hs-error-msg {
  font-size: 11px;
}
.section_newletter nav {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-top: 0;
  margin: 0 auto;
  align-items: center;
}
@media screen and (min-width:768px) {
  .section_newletter nav {
    padding-top: 0;
  }
}
.section_newletter nav .social-media {
  width: 200px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.section_newletter nav .social-media img {
  width: 20px;
  height: 20px;
}

footer {
  background-color: #000000;
  padding: 40px 20px 80px 20px;
}
@media screen and (min-width: 992px) {
  footer {
    padding: 40px 40px 80px 40px;
  }
}
footer .footer-columns, footer .footer-row {
  max-width: 1440px;
  display: flex;
  margin: auto !important;
}
footer .footer-brand {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 1rem;
}
@media screen and (min-width:768px) {
  footer .footer-brand {
    justify-content: start;
    margin-top: 20px;
  }
}
@media screen and (min-width: 992px) {
  footer .footer-brand {
    justify-content: start;
    margin-top: 0;
  }
}
footer .footer-brand img {
  height: 60%;
}
@media screen and (min-width:768px) {
  footer .footer-brand img {
    height: fit-content;
  }
}
footer .footer-column {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
@media screen and (min-width:768px) {
  footer .footer-column {
    margin-top: 0;
  }
}
footer .footer-column .accordion {
  --bs-accordion-color: white;
  --bs-accordion-bg: transparent;
}
footer .footer-column .accordion-header {
  border-top: 1px solid #858383;
  padding-top: 8px;
  margin: 0;
}
@media screen and (min-width: 992px) {
  footer .footer-column .accordion-header {
    border-top: 0;
  }
}
footer .footer-column .accordion-button {
  width: 100%;
  padding: 0;
  color: white;
  border: 0;
  cursor: pointer !important;
  background-color: transparent;
  font-family: "Louize", "Times", serif !important;
  font-weight: 400;
  font-size: 22px !important;
  text-transform: uppercase;
  line-height: 32px;
  margin-bottom: 8px;
  outline: none;
  box-shadow: none;
  text-align: left;
}
@media screen and (min-width: 992px) {
  footer .footer-column .accordion-button {
    cursor: default !important;
  }
}
footer .footer-column .accordion-button:after {
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.3335 8H12.6668' stroke='white' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 3.33331V12.6666' stroke='white' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
@media screen and (min-width: 992px) {
  footer .footer-column .accordion-button:after {
    display: none;
  }
}
footer .footer-column .accordion-button:not(.collapsed):after {
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.3335 8H12.6668' stroke='white' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  transform: none;
}
@media screen and (min-width: 992px) {
  footer .footer-column .accordion-button:not(.collapsed):after {
    display: none;
  }
}
footer .footer-column .accordion-button:not(.collapsed) {
  color: white;
  background-color: transparent;
  box-shadow: none;
}
footer .footer-column .accordion-button:focus {
  border-color: transparent;
  outline: none;
  box-shadow: none;
}
footer .footer-column .accordion-body {
  padding: 0;
  cursor: default !important;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 24px;
}
@media screen and (min-width: 992px) {
  footer .footer-column .accordion-body {
    padding-top: 0;
    padding-bottom: 0;
  }
}
footer .footer-column .accordion-body ul {
  padding-left: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
footer .footer-column .accordion-body ul li {
  list-style: none;
}
footer .footer-column .accordion-link {
  font-family: "Acumin", "Arial", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  color: white;
  font-size: 16px;
  line-height: 32px;
}
@media screen and (min-width:768px) {
  footer .footer-column .accordion-link {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }
}
@media screen and (min-width: 992px) {
  footer .footer-column .accordion-link {
    font-size: 16px;
    text-align: left;
  }
}
footer .footer-legal {
  color: white;
  font-family: "Acumin", "Arial", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  line-height: 18px;
}
@media screen and (min-width: 992px) {
  footer .footer-legal {
    text-align: left;
    margin-top: 0;
  }
}
footer .footer-legal a {
  font-family: "Acumin", "Arial", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  color: white;
  font-size: 16px;
  line-height: 24px;
}
@media screen and (min-width:768px) {
  footer .footer-legal a {
    font-size: 16px;
    line-height: 24px;
  }
}
footer .footer-legal a:hover {
  color: white;
  font-weight: 400;
}
footer .footer-language {
  color: white;
  text-align: center;
}
@media screen and (min-width: 992px) {
  footer .footer-language {
    text-align: left;
  }
}
footer .footer-language .lang {
  border-bottom: none;
}
footer .footer-language .lang.active {
  border-bottom: 1px solid white;
}
footer .footer-language a {
  text-decoration: none;
  color: white;
  font-size: 16px;
  line-height: 24px;
}
footer .footer-language a:hover {
  color: white;
  font-weight: 400;
}

.hero {
  position: relative;
  height: 580px;
  margin-top: 0;
}
@media screen and (min-width:768px) {
  .hero {
    height: 680px;
  }
}
.hero_background {
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
}
.hero_background-image {
  margin: auto;
  height: 100%;
  width: 100%;
  border-radius: 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}
.hero_content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  top: 300px;
  width: 100%;
  position: absolute;
}
@media screen and (min-width:768px) {
  .hero_content {
    top: 340px;
  }
}
@media screen and (min-width: 992px) {
  .hero_content {
    top: 340px;
  }
}
.hero_content-title {
  font-family: "Louize", "Times", serif;
  color: #FFFFFF;
  font-size: 40px;
  line-height: 48px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: -2px;
  text-align: center;
  transition: all 0.5s ease-in-out;
}
@media screen and (min-width:768px) {
  .hero_content-title {
    font-size: 64px;
    line-height: 72px;
  }
}
@media screen and (min-width: 992px) {
  .hero_content-title {
    font-size: 72px;
    line-height: 80px;
  }
}
@media screen and (min-width: 1200px) {
  .hero_content-title {
    font-size: 72px;
    line-height: 80px;
  }
}
.hero_content-subtitle {
  color: #FFFFFF;
  font-family: "Acumin", "Arial", sans-serif;
  font-weight: 400;
  text-align: center;
  max-width: 85%;
  margin: 15px auto;
  font-size: 1.25rem;
  margin-top: 20px;
  line-height: 1.75rem; /* 140% */
  transition: all 0.5s ease-in-out;
}
@media screen and (min-width: 992px) {
  .hero_content-subtitle {
    font-size: 1.25rem;
    line-height: 1.75rem; /* 140% */
    max-width: 55%;
    margin: 15px auto;
  }
}

.template-homepage .hero {
  max-width: 100%;
  position: relative;
  height: 580px;
  margin-top: 0;
  background: #EFE7E3;
}
@media screen and (min-width:768px) {
  .template-homepage .hero {
    height: 680px;
  }
}
.template-homepage .hero_background {
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  background: #EFE7E3;
}
@media screen and (min-width:768px) {
  .template-homepage .hero_background {
    top: 68px;
  }
}
@media screen and (min-width: 992px) {
  .template-homepage .hero_background {
    top: 68px;
  }
}
@media screen and (min-width: 1200px) {
  .template-homepage .hero_background {
    top: 68px;
  }
}
.template-homepage .hero_background-image {
  margin: auto;
  background-color: #EFE7E3;
  height: 100%;
  width: 100%;
  border-radius: 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  transition: width 0.3s ease;
}
@media screen and (min-width:768px) {
  .template-homepage .hero_background-image {
    width: 94%;
    border-radius: 4px;
  }
}
.template-homepage .hero_content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  top: 300px;
  width: 100%;
  position: absolute;
}
@media screen and (min-width:768px) {
  .template-homepage .hero_content {
    top: 440px;
  }
}
@media screen and (min-width: 992px) {
  .template-homepage .hero_content {
    top: 380px;
  }
}
.template-homepage .hero_content-title {
  font-family: "Louize", "Times", serif;
  color: #FFFFFF;
  font-size: 40px;
  line-height: 48px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: -2px;
  text-align: center;
  transition: all 0.5s ease-in-out;
}
@media screen and (min-width:768px) {
  .template-homepage .hero_content-title {
    font-size: 64px;
    line-height: 72px;
  }
}
@media screen and (min-width: 992px) {
  .template-homepage .hero_content-title {
    font-size: 72px;
    line-height: 80px;
  }
}
@media screen and (min-width: 1200px) {
  .template-homepage .hero_content-title {
    font-size: 72px;
    line-height: 80px;
  }
}
.template-homepage .hero_content-subtitle {
  color: #FFFFFF;
  font-family: "Acumin", "Arial", sans-serif;
  font-weight: 400;
  text-align: center;
  font-size: 1.25rem;
  margin-top: 20px;
  line-height: 1.75rem; /* 140% */
  transition: all 0.5s ease-in-out;
}
@media screen and (min-width: 992px) {
  .template-homepage .hero_content-subtitle {
    font-size: 1.25rem;
    line-height: 1.75rem; /* 140% */
  }
}

.hero_navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  background: transparent;
  margin: auto;
  padding: 0;
  z-index: 1030;
}
.hero_navbar .navbar {
  margin: 0 !important;
  padding: 0 !important;
  height: 64px;
  align-items: center !important;
  justify-content: end;
  padding: 1.5rem 2rem;
}
.hero_navbar .navbar-nav {
  padding-left: 0;
  margin: auto;
}
.hero_navbar .navbar-right {
  position: absolute;
  right: 1.5rem;
  display: flex;
  height: 64px;
  align-items: center;
  gap: 1.25rem;
}
@media screen and (min-width:768px) {
  .hero_navbar .navbar-right {
    right: 2rem;
  }
}
.hero_navbar .nav-link {
  position: relative;
  font-family: "Louize", "Times", serif;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  margin: 2rem 0 0 0;
  font-weight: 400;
  font-size: 1.5rem;
  color: #00a062;
  z-index: 11;
  line-height: 2rem;
  white-space: nowrap;
}
@media screen and (min-width: 992px) {
  .hero_navbar .nav-link {
    font-size: 15px;
    color: white;
    line-height: 20px;
    margin: 0 0 0 5px;
  }
}
.hero_navbar .nav-link::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  color: transparent;
  width: 0;
  content: "";
  height: 1px;
  background-color: #00a062;
  transition: all 0.5s;
}
@media screen and (min-width:768px) {
  .hero_navbar .nav-link::after {
    height: 1px;
    background-color: #00a062;
  }
}
@media screen and (min-width: 992px) {
  .hero_navbar .nav-link::after {
    height: 1px;
    background-color: white;
  }
}
@media screen and (min-width: 1200px) {
  .hero_navbar .nav-link::after {
    height: 1px;
    background-color: white;
  }
}
.hero_navbar .nav-link:hover::after {
  width: 100%;
  border: 0;
}
.hero_navbar .nav-link.active {
  color: #00a062;
}
@media screen and (min-width: 992px) {
  .hero_navbar .nav-link.active {
    color: white;
  }
}
.hero_navbar .nav-link.active::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #00a062;
  color: transparent;
  width: 100%;
  content: "";
  height: 0;
  transition: all 0.5s;
}
@media screen and (min-width: 992px) {
  .hero_navbar .nav-link.active::after {
    height: 1px;
    background-color: white;
  }
}
.hero_navbar .fullscreen-menu {
  width: 100% !important;
  max-width: none !important;
}
.hero_navbar .offcanvas-body {
  flex-grow: 1;
  overflow-y: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}
.hero_navbar .offcanvas.show:not(.hiding),
.hero_navbar .offcanvas.showing {
  transform: none;
  background: #EFE7E3;
}
.hero_navbar .offcanvas-header {
  height: 64px;
  padding: 1.5rem 1.5rem;
}
@media screen and (min-width: 992px) {
  .hero_navbar .offcanvas-header {
    padding: 1.5rem 2rem;
  }
}
.hero_navbar .offcanvas-header .logo {
  height: 25px;
}
.hero_navbar .offcanvas-header .btn-close {
  background-image: url("/public/img/icons/svg/close-cross.svg");
  width: 16px;
  height: 16px;
  background-size: 20px;
  background-repeat: no-repeat;
  outline: none;
  box-shadow: none;
  opacity: 1;
}
.hero_navbar .btn:focus,
.hero_navbar .btn.focus {
  outline: none !important;
  box-shadow: none !important;
  border: 0;
}
.hero_navbar .search {
  border: 0;
  background: none;
  padding: 0;
}
@media screen and (min-width: 992px) {
  .hero_navbar .search {
    padding: 0;
  }
}
.hero_navbar .navbar-toggler {
  border: none;
  padding: 0;
}
@media screen and (min-width: 992px) {
  .hero_navbar .navbar-toggler {
    padding: 0;
  }
}
.hero_navbar .navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}
.hero_navbar .navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  width: 16px;
  height: 16px;
  background-size: 24px;
  background-repeat: no-repeat;
}
.hero_navbar .dropdown .dropdown-toggle {
  cursor: pointer;
  background: none !important;
  border: none !important;
  padding: 1.5rem 0;
  margin: 0 !important;
  outline: none !important;
  box-shadow: none;
}
.hero_navbar .dropdown .dropdown-toggle::after {
  display: none;
}
.hero_navbar .dropdown .dropdown-menu {
  display: none;
  opacity: 0;
  width: fit-content;
  min-width: fit-content;
  border: none;
  background: #EFE7E3;
  border-radius: 4px;
  padding: 0.5rem 0.25rem;
  left: -37px;
  top: 61px;
  box-shadow: 0 0 15px 2px rgba(108, 108, 108, 0.3294117647);
  transition: all 200ms ease-in;
}
.hero_navbar .dropdown .dropdown-menu .dropdown-item {
  padding: 0.25rem 0.85rem;
  color: #000000;
}
.hero_navbar .dropdown .dropdown-menu .dropdown-item:focus, .hero_navbar .dropdown .dropdown-menu .dropdown-item:hover {
  color: #000000;
  background: #EFE7E3;
}
.hero_navbar .dropdown .dropdown-menu .dropdown-item .active, .hero_navbar .dropdown .dropdown-menu .dropdown-item:active {
  color: #000000;
  background: #EFE7E3;
}
.hero_navbar .dropdown .dropdown-menu.show {
  display: block;
  background: #EFE7E3;
  opacity: 1;
  width: fit-content;
  min-width: fit-content;
  transition: all 200ms ease-in;
}
.hero_navbar .offcanvas-destination .offcanvas.show:not(.hiding),
.hero_navbar .offcanvas-destination .offcanvas.showing {
  transform: none;
  height: fit-content;
  background: #FFFFFF;
}
.hero_navbar .offcanvas-destination .offcanvas-body {
  padding: 0.25rem 2rem 2rem 2rem;
}
.hero_navbar .offcanvas-destination .navbar-nav {
  position: absolute;
  flex-direction: row;
  padding-left: 0;
  left: 50%;
  display: none;
  transform: translateX(-50%);
  margin: auto;
  margin-bottom: 0;
}
@media screen and (min-width: 992px) {
  .hero_navbar .offcanvas-destination .navbar-nav {
    display: flex;
  }
}
.hero_navbar .offcanvas-destination .nav-link {
  color: black;
  margin: 0 0 0 25px;
}
.hero_navbar .offcanvas-destination .nav-link::after {
  background-color: black;
  color: black;
}
.hero_navbar .offcanvas-destination .nav-link.active {
  color: black;
}
.hero_navbar .offcanvas-destination .nav-link.active::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #000;
  color: transparent;
  width: 100%;
  content: "";
  height: 1px;
  transition: all 0.5s;
}
.hero_navbar .offcanvas-destination .navbar-right {
  right: 2.5rem;
}
.hero_navbar .offcanvas-destination .navbar-toggler {
  display: block;
}
@media screen and (min-width: 992px) {
  .hero_navbar .offcanvas-destination .navbar-toggler {
    display: none;
  }
}
.hero_navbar .offcanvas-destination .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
.hero_navbar .offcanvas-destination .btn-close {
  padding: 0;
  display: none;
}
@media screen and (min-width: 992px) {
  .hero_navbar .offcanvas-destination .btn-close {
    display: block;
  }
}
.hero_navbar .offcanvas-backdrop {
  background-color: #F5F1EF;
}
.hero_navbar .offcanvas-backdrop.show {
  opacity: 0.8;
}
.hero_navbar .menu-destination—content {
  z-index: 3;
  margin: auto;
  right: 0;
  padding: 1.5rem;
  border-radius: 4px;
  background-color: #f5f1ef;
  width: 100%;
}
.hero_navbar .menu-destination—content .destination_zone-container {
  width: 100%;
  margin: auto;
  flex-wrap: wrap;
  gap: 2rem;
  display: flex;
  padding: 0;
}
@media screen and (min-width:576px) {
  .hero_navbar .menu-destination—content .destination_zone-one {
    flex: 0 0 auto;
    width: 45%;
  }
}
@media screen and (min-width:768px) {
  .hero_navbar .menu-destination—content .destination_zone-one {
    flex: 0 0 auto;
    width: 65%;
  }
}
@media screen and (min-width: 1200px) {
  .hero_navbar .menu-destination—content .destination_zone-one {
    flex: 0 0 auto;
    width: 65%;
  }
}
@media screen and (min-width:576px) {
  .hero_navbar .menu-destination—content .destination_zone-two {
    flex: 0 0 auto;
    width: 45%;
  }
}
@media screen and (min-width:768px) {
  .hero_navbar .menu-destination—content .destination_zone-two {
    flex: 0 0 auto;
    width: 30%;
  }
}
@media screen and (min-width: 1200px) {
  .hero_navbar .menu-destination—content .destination_zone-two {
    flex: 0 0 auto;
    width: 32%;
  }
}
.hero_navbar .menu-destination—content ul {
  padding-left: 0;
  margin-bottom: 0;
}
.hero_navbar .menu-destination—content li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.hero_navbar .menu-destination—content li span {
  font-family: "Acumin", "Arial", sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0;
  font-style: normal;
  color: #828282;
}
.hero_navbar .menu-destination—content .destination_zone_title {
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  color: #00a062;
  font-family: "Acumin", "Arial", sans-serif;
  line-height: 1rem; /* 133.333% */
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 1rem;
  border-bottom: 1px solid #EFE7E3;
  transition: all 0.3s linear;
}
.hero_navbar .menu-destination—content .destination-area {
  font-family: "Louize", "Times", serif;
  display: inline-block;
  width: 100%;
  padding-bottom: 1.25rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.07rem;
  text-transform: uppercase;
}
.hero_navbar .menu-destination—content .destination-area-list {
  font-family: "Acumin", "Arial", sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0;
  font-style: normal;
  padding-top: 0.5rem;
  text-transform: none;
  padding-bottom: 0;
  padding-left: 1.25rem;
  font-weight: 400;
  line-height: 1.25rem;
}
.hero_navbar .menu-destination—content .btn-close {
  border: none;
  position: absolute;
  right: 10px;
  top: 10px;
  outline: none;
  background: none;
}
.hero_navbar .menu-destination—content .btn-close img {
  width: 22px;
}
.hero_navbar .menu-destination—content p {
  font-family: "Acumin", "Arial", sans-serif;
  font-weight: 400;
  font-size: 15px;
}
.hero_navbar .menu-destination—content p span {
  font-family: "Acumin", "Arial", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #a2a2a2;
}

.template-page .hero_navbar .navbar-brand img, .template-propertyList .hero_navbar .navbar-brand img, .template-destination .hero_navbar .navbar-brand img, .template-property-management .hero_navbar .navbar-brand img, .template-blogList-home .hero_navbar .navbar-brand img, .template-blogList-category .hero_navbar .navbar-brand img, .template-team_page .hero_navbar .navbar-brand img, .template-sitemap .hero_navbar .navbar-brand img, .template-blogPost .hero_navbar .navbar-brand img, .template-property .hero_navbar .navbar-brand img,
.template-newsletter .hero_navbar .navbar-brand img, .template-404 .hero_navbar .navbar-brand img {
  position: absolute;
  width: 200px;
  top: 18px;
  left: 18px;
  transform: translateY(0px);
}
@media screen and (min-width: 992px) {
  .template-page .hero_navbar .navbar-brand img, .template-propertyList .hero_navbar .navbar-brand img, .template-destination .hero_navbar .navbar-brand img, .template-property-management .hero_navbar .navbar-brand img, .template-blogList-home .hero_navbar .navbar-brand img, .template-blogList-category .hero_navbar .navbar-brand img, .template-team_page .hero_navbar .navbar-brand img, .template-sitemap .hero_navbar .navbar-brand img, .template-blogPost .hero_navbar .navbar-brand img, .template-property .hero_navbar .navbar-brand img,
.template-newsletter .hero_navbar .navbar-brand img, .template-404 .hero_navbar .navbar-brand img {
    left: 34px;
  }
}

.template-homepage .hero_navbar {
  background: transparent;
}
@media screen and (min-width:768px) {
  .template-homepage .hero_navbar {
    position: fixed;
    background: #EFE7E3;
    top: 0;
    left: 0;
    transition: background 0.4s;
  }
}
.template-homepage .hero_navbar .navbar-brand img {
  position: absolute;
  width: 200px;
  top: 18px;
  left: 18px;
  transform: translateY(0px);
}
@media screen and (min-width: 992px) {
  .template-homepage .hero_navbar .navbar-brand img {
    left: 34px;
  }
}
.template-homepage .hero_navbar .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
@media screen and (min-width:768px) {
  .template-homepage .hero_navbar .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
}

.template-property .hero_navbar {
  background: #EFE7E3;
}
.template-property .hero_navbar .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.template-blogList-home .hero_navbar {
  background: #EFE7E3;
  padding: 0 !important;
}
.template-blogList-home .hero_navbar .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.template-team_page .hero_navbar {
  background: #f5f1ef;
  padding: 0 !important;
}
.template-team_page .hero_navbar .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.template-blogPost .hero_navbar {
  background: white;
  padding: 0 !important;
}
.template-blogPost .hero_navbar .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.template-blogList-category .hero_navbar {
  background: #FFFFFF;
  padding: 0 !important;
}
.template-blogList-category .hero_navbar .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.template-sitemap .hero_navbar {
  background: #f5f1ef;
  padding: 0 !important;
}
.template-sitemap .hero_navbar .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.template-sitemap .hero_navbar .nav-link, .template-blogList-category .hero_navbar .nav-link, .template-blogPost .hero_navbar .nav-link, .template-blogList-home .hero_navbar .nav-link, .template-homepage .hero_navbar .nav-link, .template-property .hero_navbar .nav-link, .template-team_page .hero_navbar .nav-link {
  color: #00a062;
}
@media screen and (min-width: 992px) {
  .template-sitemap .hero_navbar .nav-link, .template-blogList-category .hero_navbar .nav-link, .template-blogPost .hero_navbar .nav-link, .template-blogList-home .hero_navbar .nav-link, .template-homepage .hero_navbar .nav-link, .template-property .hero_navbar .nav-link, .template-team_page .hero_navbar .nav-link {
    color: black;
  }
}
.template-sitemap .hero_navbar .nav-link::after, .template-blogList-category .hero_navbar .nav-link::after, .template-blogPost .hero_navbar .nav-link::after, .template-blogList-home .hero_navbar .nav-link::after, .template-homepage .hero_navbar .nav-link::after, .template-property .hero_navbar .nav-link::after, .template-team_page .hero_navbar .nav-link::after {
  background-color: #00a062;
  color: #00a062;
}
@media screen and (min-width: 992px) {
  .template-sitemap .hero_navbar .nav-link::after, .template-blogList-category .hero_navbar .nav-link::after, .template-blogPost .hero_navbar .nav-link::after, .template-blogList-home .hero_navbar .nav-link::after, .template-homepage .hero_navbar .nav-link::after, .template-property .hero_navbar .nav-link::after, .template-team_page .hero_navbar .nav-link::after {
    background-color: black;
    color: black;
  }
}

.template-destination .hero_navbar .nav-link, .template-propertyList .hero_navbar .nav-link, .template-page .hero_navbar .nav-link, .template-property-management .hero_navbar .nav-link, .template-newsletter .hero_navbar .nav-link, .template-404 .hero_navbar .nav-link {
  color: #00a062;
}
@media screen and (min-width: 992px) {
  .template-destination .hero_navbar .nav-link, .template-propertyList .hero_navbar .nav-link, .template-page .hero_navbar .nav-link, .template-property-management .hero_navbar .nav-link, .template-newsletter .hero_navbar .nav-link, .template-404 .hero_navbar .nav-link {
    color: white;
  }
}
.template-destination .hero_navbar .nav-link::after, .template-propertyList .hero_navbar .nav-link::after, .template-page .hero_navbar .nav-link::after, .template-property-management .hero_navbar .nav-link::after, .template-newsletter .hero_navbar .nav-link::after, .template-404 .hero_navbar .nav-link::after {
  background-color: #00a062;
  color: #00a062;
}
@media screen and (min-width: 992px) {
  .template-destination .hero_navbar .nav-link::after, .template-propertyList .hero_navbar .nav-link::after, .template-page .hero_navbar .nav-link::after, .template-property-management .hero_navbar .nav-link::after, .template-newsletter .hero_navbar .nav-link::after, .template-404 .hero_navbar .nav-link::after {
    background-color: white;
    color: white;
  }
}
.template-destination .hero_navbar .nav-link.active, .template-propertyList .hero_navbar .nav-link.active, .template-page .hero_navbar .nav-link.active, .template-property-management .hero_navbar .nav-link.active, .template-newsletter .hero_navbar .nav-link.active, .template-404 .hero_navbar .nav-link.active {
  color: #00a062;
}
@media screen and (min-width: 992px) {
  .template-destination .hero_navbar .nav-link.active, .template-propertyList .hero_navbar .nav-link.active, .template-page .hero_navbar .nav-link.active, .template-property-management .hero_navbar .nav-link.active, .template-newsletter .hero_navbar .nav-link.active, .template-404 .hero_navbar .nav-link.active {
    color: white;
  }
}
.template-destination .hero_navbar .nav-link.active::after, .template-propertyList .hero_navbar .nav-link.active::after, .template-page .hero_navbar .nav-link.active::after, .template-property-management .hero_navbar .nav-link.active::after, .template-newsletter .hero_navbar .nav-link.active::after, .template-404 .hero_navbar .nav-link.active::after {
  background-color: #00a062;
  color: #00a062;
}
@media screen and (min-width: 992px) {
  .template-destination .hero_navbar .nav-link.active::after, .template-propertyList .hero_navbar .nav-link.active::after, .template-page .hero_navbar .nav-link.active::after, .template-property-management .hero_navbar .nav-link.active::after, .template-newsletter .hero_navbar .nav-link.active::after, .template-404 .hero_navbar .nav-link.active::after {
    background-color: white;
    color: white;
  }
}

.template-404 {
  height: 100vh;
  background-size: cover;
}
.template-404 main {
  display: flex;
  min-height: 90vh;
}
.template-404 .content {
  font-family: "Louize", "Times", serif;
  text-align: center;
  background-color: white;
  width: fit-content;
  border-radius: 4px;
  padding: 50px;
  margin: auto;
}
.template-404 h1 {
  font-size: 36px;
  font-family: "Louize", "Times", serif;
  font-weight: 400;
  color: black;
}
.template-404 p {
  font-size: 16px;
}